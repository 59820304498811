@import './App.scss';

*::before {
	margin: 0;
	box-sizing: border-box;
}

nav {
	z-index: 9;
	font-family: 'Raleway', sans-serif;
	display: flex;
	align-items: center;
	justify-content: space-around;
	height: 8vh;
	background-color: $jpblacktransparent;
	font-size: 2.5em;
	padding: 0.7em 0;
	li {
		list-style: none;
	}
	a {
		text-decoration: none;
		color: $jpwhite;
		font-weight: 600;
	}
	.nav-link:hover {
		text-shadow: $neonHoverShadow;
	}
	.brand {
		font-size: 1.5em;
		font-weight: 700;
		@media screen and (max-width: 767px) {
			font-size: 1.2em;
		}
	}
	.nav-menu {
		display: flex;
		align-items: center;
		justify-content: space-around;
		gap: 3rem;
		@media screen and (max-width: 767px) {
			position: fixed;
			top: 12vh;
			right: 0;
			width: 75%;
			height: 93vh;
			background-color: $jpblacktransparent;
			margin-top: 0;
			flex-direction: column;
			transform: translateX(100%);
			transition: 0.5s ease-in;
			justify-content: flex-start;
			padding-top: 4em;
		}
	}
	.nav-toggler div{
		width: 2.5rem;
		height: 0.3rem;
		margin: 0.4rem;
		background: $jpwhite;
		border-radius: 5px;
		transition: 0.4s ease-in;
	}

	.nav-toggler {
		cursor: pointer;
		display: none;
		@media screen and (max-width: 767px) {
			display: block;
		}
	}

	// NAV ACTIVE CLASS 
	.nav-active {
		transform: translateX(0)
	}

	// TOGGLER ICON ANIMATION 
	.toggle .line-1 {
		transform: rotate(-45deg) translate(-11px, 5px);
	}

	.toggle .line-2 {
		opacity: 0;
	}

	.toggle .line-3 {
		transform: rotate(45deg) translate(-11px, -5px);
	}
}

