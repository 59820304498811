@import './index.scss';
@import './App.scss';

.home {
	text-align: center;
	color: $jpgrey;
	position: absolute;
	width: 100%;
	top: 0;
	bottom:0;
	background-color: $jpblack;
	// height: 700px;
	.hero {
		// font-family: 'Didact Gothic', sans-serif;
		// font-family: 'Raleway', sans-serif;
		overflow: auto;
		position: relative;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100vh;
		margin-bottom: 0;
		// filter: grayscale(100%);
		background-image: url('../assets/img/background-brick.jpeg');
		background-position: center center; /* Center the image */
  		background-repeat: no-repeat; /* Do not repeat the image */
  		background-size: cover; /* Resize the background image to maintain aspect ratio */
		.hero-textbox {
			display: flex;
			flex-direction: column;
			height: 100vh;
			align-items: center;
			justify-content: center;
			h1 {
				margin-bottom: 0;
			}
			p {
				font-size: 2.5em;
			}
			.logo {
				font-size: 10em;
				width: 100%;
				height: auto;
				user-select: none;
				@media (orientation: portrait) {
					font-size: 9em;
				}
				@media (orientation: landscape) {
					font-size: 9em;
				}
			}
			.logo b {
				font-family: $neonSignFont;
				color: #fee;
				text-shadow: $h1TextShadow;
			}
			.logo b span {
				animation: blink linear infinite 2s;
			}
			.logo b span:nth-of-type(2) {
				animation: blink linear infinite 3s;
			}
			@keyframes blink {
				78% {
					color: inherit;
					text-shadow: inherit;
				}
				79%{
					color: #333;
				}
				80% {
					
					text-shadow: none;
				}
				81% {
					color: inherit;
					text-shadow: inherit;
				}
				82% {
					color: #333;
					text-shadow: none;
				}
				83% {
					color: inherit;
					text-shadow: inherit;
				}
				92% {
					color: #333;
					text-shadow: none;
				}
				92.5% {
					color: inherit;
					text-shadow: inherit;
				}
			}
		}
	}
	.work-section {
		background-color: $jpblack;
		display: flex;
		justify-content: center;
		
		.work-container {
			margin-bottom: 10em;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 50%;
			margin: 0em 3em;
			@media screen and (max-width: 900px) {
				width: 75%;
			}
			@media screen and (max-width: 676px) {
				width: 75%;
			}
		}
		
		.work-example {
			padding: 1em 0em;
			display: flex;
			flex-direction: column;
			width: 100%;
		}
		h4 {
			align-self: start;
			font-size: 3em;
			font-weight: 400;
			@media screen and (max-width: 676px) {
				font-size: 1.8em;
			}
		}
		.work-title {
			font-weight: 900;
		}
		.video-wrapper {
			position: relative;
			padding-bottom: 56.25%; /* 16:9 */
			height: 0;
		}
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			&:hover {
				box-shadow: $redBoxShadow;
			}
		}
		.playlist-btn {
			margin-top: 3em;
			margin-bottom: 3em;
		}
	}
}

