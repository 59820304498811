@import './index.scss';
@import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&family=Mulish:wght@200;300;400;500;600;700;800;900&family=Playfair+Display:wght@400;500;600;700;800;900&family=Raleway:wght@100;300;400;500;600;700;800;900&family=Vibur&display=swap');

// font rules 
// font-family: 'Didact Gothic', sans-serif;
// font-family: 'Mulish', sans-serif;
// font-family: 'Playfair Display', serif;
// font-family: 'Raleway', sans-serif;
// font-family: 'Vibur', cursive;

// FONT VARIABLES
$mainFont: 'Mulish', sans-serif;
$neonSignFont: 'Vibur', cursive;


// COLOR VARIABLES
$jpblack: #000000;
$jpblacktransparent: rgba(0, 0, 0, 0.731);
$jpnavy: #14213D;
$jpnavylite: #19294c;
$jporange: #f6c16b;
$jporangehover: #3A3C45;
$jpgrey: #E5E5E5;
$jpgreytransparent: #000000b9;
$jpwhite: #FFFFFF;
$jpred: rgb(242, 68, 102);

// NEON EFFECT VARIABLES
$neonHoverShadow: 0 0 2px $jpwhite, 0 0 2.5px $jpwhite, 0 0 5px $jpwhite, 0 0 20px $jpwhite, 0 0 55px $jpwhite, 0 0 70px $jpwhite, 0 0 80px $jpwhite, 0 0 100px $jpwhite;
$h1TextShadow: 0 -40px 100px, 0 0 2px, 0 0 1em $jpred, 0 0 0.5em $jpred, 0 0 0.1em $jpred, 0 10px 3px $jpblack;
$redBoxShadow: 0 0 2px $jpwhite, 0 0 20px $jpred, 0 0 55px $jpred, 0 0 70px $jpred;

.site-wrapper {
	width: 100vw;
	display: flex;
	flex-direction: column;
	background-color: $jpblack;
}

.container {
	max-width: 1020px;
	margin-right: 3em;
	margin-left: 3em;
	font-family: $mainFont;
	@media screen and (max-width: 920px) {
		max-width: 90%;
	}
}

.hero {
	height: 100vh;
	margin-bottom: 0;
	background-image: url('../assets/img/background-brick.jpeg');
	background-position: center center; /* Center the image */
  	background-repeat: no-repeat; /* Do not repeat the image */
  	background-size: cover; /* Resize the background image to maintain aspect ratio */
}

