@import './App.scss';

.neon-header {
	margin-top: 1em;
	margin-bottom: 0.5em;
	font-size: 10em;
	color: $jpgrey;
	@media screen and (max-width: 767px) {
		font-size: 7em;
	}
}

.neon-header-glow {
	text-shadow: 0 0 3.5px #fff,
		0 0 5px #fff,
		0 0 10px #fff,
		0 0 20px $jpwhite,
		0 0 55px $jpwhite,
		0 0 70px $jpwhite,
		0 0 80px $jpwhite,
		0 0 100px $jpwhite;
	transition-duration: 4s;
}