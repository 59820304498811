@import './App.scss';

footer {
	display: flex;
	justify-content: center;
	width: 100%;
	min-height: 3.5em;
	background-color: $jpblack;
	color: $jpgrey;
	// position: absolute;
	// bottom: 0;
	text-align: center;
	font-size: 1.5em;
	box-shadow: 0 0 1px $jporange, 0 0 4px $jporange, 0 0 8px $jporange;
	.footer-container {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		justify-content: space-between;
		@media screen and (max-width: 767px) {
			flex-direction: column;
		}
	}
	a {
		text-decoration: none;
		color: $jpgrey;
	}
	.footer-top {
		margin: 15px 0;
	}
	svg {
		color: white;
	}
	.footer-bottom {
		display: flex;
		align-items: center;
		.social-link-item {
			font-size: 1.5em;
			padding-left: 0.4em;
			padding-right: 0.4em;
			fill: $jpgrey;
			@media screen and (max-width: 767px) {
				font-size: 1.2em;
			}
		p {
			font-size: 0.7em;
		}
	}
	}
	.footer-item {
		padding-left: 1em;
		padding-right: 1em;
		@media screen and (max-width: 767px) {
			padding-left: 0.5em;
			padding-right: 0.5em;	
		}
	}

}

