@import './App.scss';

.contact-section {
	color: $jpgrey;
	width: 100%;
	padding-bottom: 12em;
	@media screen and (max-width: 767px) {
		max-width: 80%;
		padding-bottom: 3em;
	}
}

.top-row {
	display: flex;
	width: 100%;
	justify-content: space-between;
	@media screen and (max-width: 767px) {
		flex-direction: column;
	}
}

.contact-user-info {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin-bottom: 1em;
	min-width: 47%;
	&:hover {
		label {
			font-weight: 700;
			font-size: 2.3em;
		}
	}
	label {
		font-size: 2em;
		font-weight: 600;
		margin-left: 0.2em;
		margin-bottom: 0.2em;
		align-self: flex-start;
		z-index: 9;
	}
	input {
		background-color: $jpgrey;
		height: 2.2em;
		border-radius: 5px;
		padding: 0.4em;
		padding-left: 1em;
		&:hover {
			box-shadow: 0 0 20px rgb(242, 68, 102), 0 0 55px rgb(242, 68, 102);
		}
	}
	textarea {
		border-radius: 5px;
		padding: 0.4em;
		padding-left: 1em;
		background-color: $jpgrey;
		&:hover {
			box-shadow: 0 0 20px rgb(242, 68, 102), 0 0 55px rgb(242, 68, 102);
		}
	}
}