@import './App.scss';

// .about-page {
// 	text-align: center;
// 	color: $jpgrey;
// 	position: absolute;
// 	width: 100%;
// 	top: 0;
// 	bottom:0;
// }

.about {
	color: $jpgrey;
	background-color: $jpblack;
	display: flex;
	flex-direction: column;
	// justify-content: center;
	align-items: center;
	// position:absolute;
	.about-container {
		// margin-top: 8em;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.about-header {
		display: flex;
		justify-content: space-around;
		align-items: center;
		flex-direction: row;
		font-size: 2.5em;
		margin-bottom: 3em;
		margin-top: 2em;
		height: calc(100vh - 7em);
		@media screen and (max-width: 920px) {
			margin: 3em 3em 8em 3em;
			flex-direction: column;
			font-size: 2.6em;
			height: auto;
		}
		@media screen and (max-width: 450px) {
			margin-left: 1em;
			margin-right: 1em;
			margin-bottom: 5em;
			font-size: 2em;
		}
		.hi-jon {
			// font-family: 'Playfair Display', serif;
			font-size: 1.7em;
			font-weight: 900;
			@media screen and (max-width: 450px) {
				display: none;
			}
		}
	}
	.abt-header-text {
		max-width: 45%;
		text-align: justify;
		background-color: rgba(0, 0, 0, 0.754);
		padding: 3em;
		margin-left: -7em;
		margin-bottom: -7em;
		@media screen and (max-width: 920px) {
			text-align: left;
			max-width: 100%;
			margin-left: 0;
			margin-bottom: 0;
			margin-top: 3em;
			padding: 3em 0 0 0;
		}
	}
	.abt-header-img {
		// min-width: 45%;
		max-width: 50%;
		max-height: 100%;
		// box-shadow: 3px 3px $jpblack;
		box-shadow: $redBoxShadow;
		@media screen and (max-width: 920px) {
			max-width: 100%;
		}
	}
}

.text-center {
	text-align: center;
}

.mw-75 {
	max-width: 75%;
}

.submit-btn {
	margin-top: 1em;
}