@import './App.scss';

.btn {
	font-size: 3em;
	font-weight: 700;
	padding: 0.7em 1.4em;
	border: 2px solid $jpgrey;
	border-radius: 5px;
	margin-bottom: 3em;
	text-align: center;
	background-color: transparent;
	color: $jpgrey;
	text-decoration: none;
	&:hover{
		transform: scale(1.1);
		text-shadow: 0 0 5px $jpgrey, 0 0 20px $jpgrey;
		box-shadow: 0 0 2px #fff, 0 0 10px $jpgrey, 0 0 40px $jpgrey, 0 0 80px $jpgrey;
	}
	@media screen and (max-width: 767px) {
		margin-bottom: 1.3em;
		font-size: 2.5em;
	}
}